.login-button {
    color: #757575;
    border: 1px solid #9f9f9f;
    font-size: 0.8em;
    width: 38px;
    height: 20px;
    padding: 2px 10px;
    text-align: center;

    &:hover {
        background: #f4f4f4;
    }

    @media screen and (max-width: 768px) {
        border: none;
    }
}