.carousel {
    position: relative;
    width: 100%;
    height: 480px;
    transition: display 1s;

    @media screen and (max-width: 768px) {
        height: 240px;
        font-size: 0.4em;
    }

    .carousel-item {
        height: 100%;
        background: gray center no-repeat;
        background-size: cover;

        .carousel-item-text {
            position: absolute;
            width: 100%;
            top: 48%;
            text-align: center;
            color: white;
            font-size: 2em;
            font-weight: 300;
        }
        .carousel-item-text-title {
            top: 22%;
            font-size: 3.8em;
        }
    }

    .carousel-active {
        display: block;
        height: 100%;
    }

    .carousel-inactive {
        display: none;
    }

    .carousel-arrows {
        position: absolute;
        width: 98%;
        transform: translateY(-50%);
        left: 1%;
        top: 50%;
        display: flex;
        justify-content: space-between;
    }

    .carousel-navigator {
        position: absolute;
        bottom: 30px;
        transform: translateX(-50%);
        left: 50%;
        display: flex;

        .carousel-navigator-button {
            width: 30px;
            height: 4px;
            background: white;
            opacity: 0.4;
            margin: 0 2px;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .carousel-progress-bar {
        position: absolute;
        bottom: 0;
        height: 6px;
        background: white;
        opacity: 0.4;
    }
}