.header {
    width: 100%;

    .header-global {
        position: relative;
        background: #f8f8f8;
        max-width: 1200px;
        padding: 0 30px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 101;

        @media screen and (max-width: 768px) { padding: 0 10px; }

        .header-home-logo {
            width: 100px;
            height: 40px;
            background: url(../../images/logo_home.png) center no-repeat;
            background-size: contain;

            @media screen and (max-width: 768px) { width: 80px; height: 20px; }
        }
    }

    .header-main {
        position: relative;
        z-index: 100;
        background: #3f3f38;

        .header-main-inner {

            max-width: 1200px;
            margin: auto;
            padding: 0 30px;

            @media screen and (max-width: 768px) { padding: 0 4px; }

            .header-desktop {
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                .header-desktop-nav {
                    display: flex;
                    align-items: center;
    
                    .header-desktop-logo {
                        width: 160px;
                        height: 70px;
                        margin-right: 40px;
                        background: url(../../images/logo_mahjong_white.png) center no-repeat;
                        background-size: contain;
                    }
    
                    .header-desktop-nav-item {
                        height: 90px;
                        padding: 0 36px;
                        display: flex;
                        align-items: center;
                        color: white;
        
                        &:hover {
                            background: rgba($color: #ffffff, $alpha: 0.1);
                        }
                    }
                }
    
                .header-desktop-gamestart-button {
                    width: 140px;
                    height: 70px;
                    background: orange;
                    text-align: center;
                    box-sizing: border-box;
                    padding: 4px;
                    font-size: 1.6em;
                    font-weight: 500;
                    color: white;
                    border-radius: 10px;
                    border: 1px solid lighten($color: orange, $amount: 30);
                    cursor: pointer;
                    &:hover {
                        background: lighten($color: orange, $amount: 8);
                    }
                }
            }
    
            .header-mobile {
                display: none;
                justify-content: space-between;
                align-items: center;
    
                .header-mobile-logo {
                    width: 100px;
                    height: 50px;
                    background: url(../../images/logo_mahjong_white.png) center no-repeat;
                    background-size: contain;
                }
    
                .header-mobile-gamestart-button {
                    width: 200px;
                    height: 50px;
                    background: center no-repeat orange;
                }
            }
    
            @media screen and (max-width: 768px) {
                .header-desktop { display: none; }
                .header-mobile { display: flex; }
            }
        }
    }

    .header-drawer {
        position: fixed;
        width: 100%;
        transition: top 0.5s;
        background: white;
        z-index: 99;
    }
}