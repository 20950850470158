.notice-previewer {
    margin: 100px auto;
    max-width: 1200px;
    padding: 0 30px;

    @media screen and (max-width: 768px) {
        padding: 0 10px;
        font-size: 0.8em;
    }

    .notice-previewer-title {
        font-size: 1.4em;
        font-weight: 500;
        color: #373737;
        border-bottom: 1px solid #bcbcbc;
    }

    .notice-previewer-list {
        color: #757575;
        padding: 30px 0 80px;
    }
}