body {
  margin: 0;
  padding: 0;
  background: #f8f8f8;
  font-family: 'Roboto', 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}